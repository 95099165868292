<template>

  <div>
  <div
    class="album-detail"
    :style="pageStyle"
    ref="scrollBox" style="height:100vh;overflow-y:scroll;" @scroll="handleScroll"
  >
    <CommonHeader title="专场详情" />
    <van-pull-refresh v-model="loadingr" @refresh="onRefresh" success-text="刷新成功">
    <div
      class="album-header"
      v-show="albumHeaderShow"
    >
      <div class="album-header-t flex align-items-c">
        <div
          class="back flex align-items-c"
          @click="backAction"
        >
          <img
            src="https://artimg2.artart.cn/image/20220218/98e4c14a8b9d458a401c20a2ed1666fa.png"
            alt=""
          >
        </div>
        <div class="n fw500 line1 flex-one">{{ albumData.album_name }}</div>
      </div>
      <div class="album-header-b flex align-items-c">
        <AuctionStatus
          :status="1"
          :type="albumData.status"
          sty="display: inline-block;position: relative; top: -0.05333rem;margin-right: 0.0666rem;"
        />
        <div class="ml5">
          <template v-if="albumData.album_status === 1 && (albumData.goTime &lt; 0 || albumData.goTime > 3600 * 6)"><span>{{parseTime(albumData.album_start_time, '{m}月{d}日 {h}:{i}')}}</span><span class="ml5">开拍</span></template>
          <template v-else-if="albumData.album_status === 2 && albumData.album_type == 4"><span>出价后{{ albumData.interval_time / 60 }}分钟结拍</span></template>
          <template v-else-if="albumData.album_status === 2 && albumData.album_type != 1"><span>当前{{albumData.now_auction_order_num}}/{{albumData.auction_num}}件</span></template>
          <template v-else-if="albumData.album_status === 2 && (albumData.goTime &lt; 0 || albumData.goTime > 3600 * 6 || albumData.end_type == 2)"><span>{{ parseTime(albumData.first_end_time, '{m}月{d}日 {h}:{i}') }}</span><template v-if="albumData.end_type == 2"><span class="ml5">至</span><span class="ml5">{{ parseTime(albumData.album_end_time, parseTime(albumData.first_end_time, '{m}') === parseTime(albumData.album_end_time, '{m}') ? '{h}:{i}' : '{d}日 {h}:{i}') }}</span></template><span class="ml5">结拍</span></template>
          <template v-else-if="albumData.album_status === 3"><span
              class="ml5"
              style="font-weight: 400;color: #666;"
            >{{ parseTime(albumData.album_end_time, '{y}年{m}月{d}日 {h}:{i}') }}</span>结拍</template>
          <template v-if="albumData.album_status === 1 && albumData.goTime > 0 && albumData.goTime <= 3600 * 6">距开拍 </template>
          <template v-else-if="albumData.album_status === 2 && albumData.end_type == 1 && albumData.goTime > 0 && albumData.goTime <= 3600 * 6">距结拍 </template>
        </div>
        <div class="flex-one">
          <template v-if="(albumData.album_status === 1 || (albumData.album_status === 2 && albumData.end_type == 1)) && albumData.goTime > 0 && albumData.goTime <= 3600 * 6">
            <van-count-down
              :time="albumData.goTime * 1000"
              @finish="countDownFinish"
            >
              <template #default="timeData">
                <div class="flex align-items-c ft12">
                  <template v-if="timeData.hours">
                    <div
                      class="ml3 mr3"
                      :style="{ color: color_data.specialColor }"
                    >{{ timeData.hours }}</div>
                    <div style="font-size: 0.32rem;color: #000;">时</div>
                  </template>
                  <template v-if="timeData.minutes">
                    <div
                      class="ml3 mr3"
                      :style="{ color: color_data.specialColor }"
                    >{{ timeData.minutes }}</div>
                    <div style="font-size: 0.32rem;color: #000;">分</div>
                  </template>
                  <div
                    class="ml3 mr3"
                    :style="{ color: color_data.specialColor }"
                  >{{ timeData.seconds }}</div>
                  <div style="font-size: 0.32rem;color: #000;">秒</div>
                </div>
              </template>
            </van-count-down>
          </template>
        </div>
        <div class="search flex align-items-c">
          <div class="flex-one">
            <input
              style="width: 100%;"
              type="text"
              v-model="keyword"
              @keyup.enter="goSearchAction"
              placeholder="搜索"
            />
          </div>
          <img
            v-if="!queryParams.keyword"
            class="icon"
            src="https://artimg2.artart.cn/image/20220319/a3da1235c89feffd42a86a22bc87fa2b.png"
            @click="goSearchAction"
          >
          <img
            v-else
            class="icon"
            src="https://artimg2.artart.cn/image/20220322/807a11006645b50c219b8f3ab8fb8ef3.png"
            @click="clearSearchAction"
          >
        </div>
      </div>
    </div>
    <van-list
      v-model:loading="loading"
      :immediate-check="false"
      :finished="finished"
      :finished-text="total > 0 ? '没有更多了' : ''"
      @load="loadList"
    >
      <template v-if="pageStyleData">
        <img
          v-if="!pageStyleData.show_header_view"
          :style="{
            width: '100%',
            display: 'block',
            overflow: 'hidden'
          }"
          :src="albumData.album_image + '!m640'"
        />
      </template>
      <template v-if="pageContentData">
        <template
          v-for="(item, index) in pageContentData"
          :key="`pageContentData${index}`"
        >
          <template v-if="item.content.type === 'name'">
            <Name
              :albumData="albumData"
              :facade="item.facade"
              :pageInfo="pageStyleData"
            />
          </template>
          <template v-else-if="item.content.type === 'status'">
            <Status
              :albumData="albumData"
              :facade="item.facade"
              :pageInfo="pageStyleData"
              @showFollowOfficial="showFollowOfficialAction"
            />
          </template>
          <template v-else-if="item.content.type === 'detail'">
            <Detail
              :albumData="albumData"
              :facade="item.facade"
              :pageInfo="pageStyleData"
            />
          </template>
          <template v-else-if="item.content.type === 'desc'">
            <Desc
              :albumData="albumData"
              :facade="item.facade"
              :pageInfo="pageStyleData"
            />
          </template>
          <template v-else-if="item.content.type === 'list'">
            <div :style="{ marginTop: (item.facade.marginTop / 75) + 'rem' }">
              <div :style="{ padding: `0 ${(pageStyleData.page_margin / 75)}rem`, background: '#fff' }">
                <div class="tab flex align-items-c">
                  <div
                    class="search flex align-items-c flex-one"
                    v-show="showSearch || queryParams.keyword"
                  >
                    <div class="flex-one">
                      <input
                        ref="searchEL"
                        style="width: 100%;"
                        type="text"
                        v-model="keyword"
                        id="searchView"
                        @keyup.enter="goSearchAction"
                        placeholder="搜索"
                      >
                    </div>
                    <img
                      v-if="!queryParams.keyword"
                      class="icon"
                      src="https://artimg2.artart.cn/image/20220319/a3da1235c89feffd42a86a22bc87fa2b.png"
                      @click="goSearchAction"
                    >
                    <img
                      v-else
                      class="icon"
                      src="https://artimg2.artart.cn/image/20220322/807a11006645b50c219b8f3ab8fb8ef3.png"
                      @click="clearSearchAction"
                    >
                  </div>
                  <div class="flex-one flex align-items-c ft12">
                    <div
                      class="item"
                      :class="{ 'curr': tab == 0 && !queryParams.keyword }"
                      :style="{ color: tab == 0 && !queryParams.keyword ? color_data.specialColor : '' }"
                      @click="changeTabAction(0)"
                    >全部 <span v-if="albumData.auction_num">{{ albumData.auction_num }}</span>
                      <div
                        class="bg"
                        :style="{ background: color_data.specialColor }"
                      />
                    </div>
                    <div
                      class="item"
                      :class="{ 'curr': tab == 1 && !queryParams.keyword }"
                      :style="{ color: tab == 1 && !queryParams.keyword ? color_data.specialColor : '' }"
                      @click="changeTabAction(1)"
                    >我的参拍 <span v-if="albumData.user_bid_num">{{ albumData.user_bid_num }}</span>
                      <div
                        class="bg"
                        :style="{ background: color_data.specialColor }"
                      />
                    </div>
                    <div
                      class="item"
                      :class="{ 'curr': tab == 2 && !queryParams.keyword }"
                      :style="{ color: tab == 2 && !queryParams.keyword ? color_data.specialColor : '' }"
                      @click="changeTabAction(2)"
                    >即将结拍 <span>{{ albumData.auction_num - albumData.auction_end_num }}</span>
                      <div
                        class="bg"
                        :style="{ background: color_data.specialColor }"
                      />
                    </div>
                  </div>
                  <div class="icon flex align-items-c">
                    <div
                      v-if="list.style == 4"
                      @click="editTypeAction(2)"
                      class="icon-view"
                    >
                      <img src="https://artimg2.artart.cn/image/20220318/91d8350283afd768f1ce5e3a8b1c9e55.png">
                    </div>
                    <div
                      v-else-if="list.style == 1"
                      @click="editTypeAction(4)"
                      class="icon-view"
                    >
                      <img src="https://img.artart.cn/node/2020/323/1584970667142927825.png">
                    </div>
                    <div
                      v-else-if="list.style == 2"
                      @click="editTypeAction(1)"
                      class="icon-view"
                    >
                      <img src="https://artimg2.artart.cn/image/20220416/478cb647f62254d6d24c61cd2750127f.png">
                    </div>
                    <div
                      v-if="queryParams.keyword"
                      class="close-search"
                      @click="clearSearchAction"
                    >
                      取消搜索
                    </div>
                    <div
                      v-else
                      class="icon-view"
                      @click="openSearchAction"
                    >
                      <img src="https://artimg2.artart.cn/image/20220108/f82cd92e7d77f3b3beba77261f7f0ea1.png">
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="list"
                :style="{ padding: `0 ${(pageStyleData.page_margin / 75)}rem`, marginTop: '0.26666rem' }"
              >
                <Auction
                  :style="{ marginLeft: ((0 - (list.style === 2 ? pageStyleData.page_margin : 0)) / 75) + 'rem' }"
                  :content="list"
                  :facade="facade"
                  :isCountDown="albumData.album_type == 1 && albumData.end_type == 2 && albumData.goTime > 0 && albumData.goTime <= 3600 * 6"
                />
              </div>
            </div>
          </template>
        </template>
      </template>
    </van-list>
    
    <template v-if="pNMSG">
      <div
        class="show-confirm flex"
        @click.stop="kong"
      >
        <div class="show-title">法律条款
          <div
            class="n-header-back flex align-items-c justify-c"
            @click='backAction'
          >
            <img
              class="img"
              src="https://artimg2.artart.cn/image/20211029/6f2d1b371322f39581c1c3d8cc2c730d.png"
            >
          </div>
        </div>
        <div
          class="show-content flex-one"
          style="overflow: hidden;"
        >
          <div class="c">
            <div v-html="pNMSG"></div>
            <div class="bottom-checkbox flex">
              <div class="l">
                <van-checkbox
                  v-model="isConfirm"
                  icon-size="100px"
                  shape="square"
                ></van-checkbox>
              </div>
              <div
                class="r flex-one"
                style="overflow: hidden"
                @click="isConfirmAction"
              >
                <div class="ft14 fw500">本人确认</div>
                <div class="ft12">1、本人已审阅并充分理解《{{ pNTitle }}》及竞买须知类文件内容，知悉应自拍卖标的成交之时起72小时内主动付清购买价款，否则定金将被没收。</div>
                <div class="ft12">拍卖人已通过视频对重点条款内容进行了充分地解释和说明。本人已通过点击下方视频超链接观看视频并进一步咨询了业务人员，对视频和条款内容不存在疑问或异议。</div>
                <div class="ft12">2、拍卖人并非采用网络方式销售商品，本人已于竞买前亲自审看拍卖标的原物，认可拍卖标的状态。本人认可拍卖标的不是日常消费品，属于特殊商品，根据其性质不宜退货。本人确认并遵守:拍卖一旦成交，买受人不得主张退货或换货。</div>
              </div>
            </div>
          </div>
          <div class="btn-bottom flex align-items-c">
            <div class="l ft14 colorGray">请上滑看完本条款再同意~</div>
            <div
              class="r flex-one hong"
              @click="confirmOk"
            >同意</div>
          </div>
        </div>
      </div>
    </template>
    <FollowQrcodeUrl
      :is-show="showFollowOfficial && is_follow_official_account === false"
      has-close="1"
      @close="showFollowOfficial = false;"
    ></FollowQrcodeUrl>
    </van-pull-refresh>
  </div>
  <AlbumBottomBtn
      :albumData="albumData"
      :isPayBond="isPayBond"
      @userNumberPlate="getNumberPlateAction"
      @showPayBondMoney="showPayBondMoneyAction"
      @showFollowOfficial="showFollowOfficialAction"
    />
    <BidBondMoney
      :showPay="showPayBondMoney"
      :albumData="albumData"
      :account="account"
      @payOk="payOk"
      @payClear="payClear"
    ></BidBondMoney>
  </div>

</template>

<script scoped>
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import {
  ref,
  onBeforeMount,
  computed,
  nextTick,
  onActivated,
  reactive,
  watch
} from "vue";
import { Toast, Field, Sticky, CountDown, Checkbox } from "vant";
import { usePageVisibility } from '@vant/use';
import { getDiyPage } from "@/api/system";
import { auctionList, albumDetail, checkAlbumBondPay } from "@/api/auction";
import { getAgreement } from "@/api/system";
import { useEventListener } from "@vant/use";
import { parseTime } from "@/utils/util";
import Name from "./modules/AlbumName";
import Status from "./modules/AlbumStatus";
import Detail from "./modules/AlbumDetail";
import Desc from "./modules/AlbumDesc";
import AlbumBottomBtn from "./modules/AlbumBottomBtn";
import Auction from "@/components/DiyComponent/Auction";
import BidBondMoney from "./modules/BidBondMoney.vue";
import AuctionStatus from "@/components/DiyComponent/AuctionStatus";
import { loginSocketApi, joinRoomApi } from "@/api/live";
import { shareConfig } from "@/utils/share";
import FollowQrcodeUrl from "@/components/FollowQrcodeUrl";

import webSocket from "@/utils/webSocket";

export default {
  name: "auctionAlbumDetail",
  components: {
    [Field.name]: Field,
    [Sticky.name]: Sticky,
    [CountDown.name]: CountDown,
    [Checkbox.name]: Checkbox,
    Name,
    Status,
    Detail,
    Desc,
    Auction,
    AlbumBottomBtn,
    BidBondMoney,
    AuctionStatus,
    FollowQrcodeUrl,
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const id = ref(""); // 拍品ID
    const albumData = ref(""); // 拍品详情信息
    const pageStyleData = ref(""); // 详情页面配置
    const pageContentData = ref(""); // 详情页面组件配置
    const pageVisibility = usePageVisibility();

    const loading = ref(true);
    const pageLoading = ref(true);
    const finished = ref(false);
    const total = ref(0);
    const keyword = ref("");
    const list = ref({
      style: 4,
      renderData: [],
    });
    const queryParams = ref({
      page: 1,
      limit: 10,
    });

    const facade = ref("");
    const typeSetting = ref([]);

    const isPayBond = ref(false);
    const showPayBondMoney = ref(false);

    const color_data = computed(() => {
      return store.getters.color_data;
    });

    // 挂载之前方法 请求一些基本信息
    onBeforeMount(() => {
      id.value = router.currentRoute.value.query.id;
      queryParams.value.album_id = id.value;

      if (!id.value) {
        Toast("该专场不存在");
        router.back();
        return false;
      }

      const pageStyle = localStorage.getItem("albumPageStyle");
      if (pageStyle) {
        const pageStyleN = JSON.parse(pageStyle);
        if (pageStyleN.pageStyleData && pageStyleN.pageContentData) {
          pageStyleData.value = pageStyleN.pageStyleData;
          pageContentData.value = pageStyleN.pageContentData;
        }
      }
      loadDetail();
      // 专场详情配置
      getDiyPage({
        type: "album_detail_page",
      })
        .then((res) => {
          pageStyleData.value = res.data.config_data;
          pageContentData.value = res.data.content;

          localStorage.setItem(
            "albumPageStyle",
            JSON.stringify({
              pageStyleData: res.data.config_data,
              pageContentData: res.data.content,
            })
          );

          // 拍品列表配置
          getDiyPage({
            type: "auction_component",
          })
            .then((res) => {
              const configData = res.data.config_data;
              let f = {};
              let t = {};
              for (const key in configData) {
                const m = configData[key];
                if (key !== "type_setting") {
                  f[key] = m;
                } else {
                  t = m;
                }
              }
              if (t[4]) {
                Object.assign(f, t[4]);
              }
              f.page_margin = 0;
              facade.value = f;
              typeSetting.value = t;
            })
            .catch((message) => Toast(message));
        })
        .catch((message) => Toast(message));
      store.dispatch("user/getInfo");
    });
    let two = false;
    const sdata = reactive({
      socketServe: webSocket.Instance,
    });
    watch(pageVisibility, (value) => {
      if (value == 'visible' && store.getters.token && !is_follow_official_account.value) {
        store.dispatch("user/getIsFollowOfficialAccountInfo");
      }
    });
    onActivated(() => {
      if(scrollTop.value > 0){
        setTimeout(() => {
          nextTick(() => {
            scrollBox.value.scrollTop = scrollTop.value;
          })
        }, 10)
      }
      if (store.getters.token && !is_follow_official_account.value) {
        store.dispatch("user/getIsFollowOfficialAccountInfo");
      }
      loadAccount();
      if (two) {
        finished.value = false;
        loading.value = false;
        pageLoading.value = false;
        loadDetail(1);
      }

      if (!webSocket.Instance.ws) {
        webSocket.Instance.connect();
        sdata.socketServe = webSocket.Instance;
        sdata.socketServe.registerCallBack("getMessage", (data) => {
          sockedMes(data);
        });
        sdata.socketServe.registerCallBack("openSocket", () => {});
      } else {
        sdata.socketServe.registerCallBack("getMessage", (data) => {
          sockedMes(data);
        });
        joinRoom();
      }
      two = true;
    });

    const client_id = ref("");

    const sockedMes = (d) => {
      console.log(d);
      if (d.type == "init") {
        client_id.value = d.client_id;
        webSocket.Instance.client_id = d.client_id;
        loginSocket();
      } else if (d.type === "auction_bid" || d.type === "auction_bid_proxy") {
        // 拍品出价
        const index = listIds.value.indexOf(d.data.id);
        if (index != -1) {
          list.value.renderData[index].now_price = d.data.now_price;
          list.value.renderData[index].bid_num = d.data.bid_num;
        }
      } else if (d.type === "auction_bid_confirm") {
        // 拍品出价
        const index = listIds.value.indexOf(d.data.id);
        if (index != -1) {
          list.value.renderData[index].now_price = d.data.now_price;
        }
      } else if (d.type === "auction_bid_del") {
        // 删除最高价
        const index = listIds.value.indexOf(d.data.id);
        if (index != -1) {
          list.value.renderData[index].now_price = d.data.now_price;
          list.value.renderData[index].bid_num = d.data.bid_num;
        }
      } else if (d.type === "auction_remake") {
        // 重拍
        const index = listIds.value.indexOf(d.data.auction_info.product_id);
        if (index != -1) {
          list.value.renderData[index] = d.data.auction_info;
        }
      }
    };

    const loginSocket = () => {
      loginSocketApi({
        client_id: client_id.value,
      })
        .then(() => {
          joinRoom();
        })
        .catch((message) => Toast(message));
    };

    const joinRoom = () => {
      joinRoomApi({
        client_id: client_id.value || webSocket.Instance.client_id,
        room_type: "album",
        room_id: id.value,
      })
        .then((res)=>{
          if(res.data && res.data.status == 40099){
            webSocket.Instance.connect();
            sdata.socketServe = webSocket.Instance;
            sdata.socketServe.registerCallBack("getMessage", (data) => {
              sockedMes(data);
            });
            sdata.socketServe.registerCallBack("openSocket", () => {});
          }
          
        })
        .catch((message) => Toast(message));
    };
    const pNTitle = ref("");
    const pNMSG = ref("");
    const loadDetail = (refresh) => {
      // 专场详情
      albumDetail(id.value)
        .then((res) => {
          const d = res.data;
          if (d.album_type == 4) {
            const p1 = localStorage.getItem(`clickPageNoticeMsg${id.value}`);
            if (!p1 || p1 == undefined || p1 == "undefined") {
              getAgreement({ type: 5 }).then((res) => {
                if (res.data) {
                  if (res.data.info && res.data.info.message) {
                    pNTitle.value = res.data.info.title;
                    pNMSG.value = res.data.info.message;
                  }
                }
              });
            }
          }
          if (d.album_status === 1) {
            d.status = 2;
            d.goTime = d.album_start_time - res.server_time;
          } else if (d.album_status === 2) {
            d.status = 1;
            d.goTime = d.album_end_time - res.server_time;
          }

          if (d.bond_type == 1 && d.bond_price) {
            isPayBond.value = res.data.isPayBond;
          }

          albumData.value = d;
          shareConfig(d.album_name, "", d.album_image, "", "", location.href);

          pageLoading.value = false;
          loadList(refresh);
        })
        .catch((message) => Toast(message));
    };
    const loadAccount = () => {
      store.dispatch("user/getAccountInfo");
    };
    // 页面配置
    const pageStyle = computed(() => {
      const data = {
        backgroundColor: pageStyleData.value.background,
        backgroundImage: `url(${pageStyleData.value.bg_image})`,
        backgroundRepeat:
          pageStyleData.value.bg_image_mode === 3 ? "repeat-y" : "no-repeat",
        backgroundPosition:
          pageStyleData.value.bg_image_mode === 3 ||
          pageStyleData.value.bg_image_mode === 4
            ? "center top"
            : "center",
      };
      if (pageStyleData.value.bg_image_mode === 1) {
        data.backgroundSize = `contain;`;
      } else if (pageStyleData.value.bg_image_mode === 2) {
        data.backgroundSize = `cover;`;
      } else if (pageStyleData.value.bg_image_mode === 3) {
        data.backgroundSize = `10rem;`;
      } else if (pageStyleData.value.bg_image_mode === 4) {
        data.backgroundSize = `10rem;`;
      }
      return data;
    });

    const editTypeAction = (type) => {
      console.log(type, typeSetting.value);
      if (typeSetting.value[type]) {
        const f = facade.value;
        for (const key in typeSetting.value[type]) {
          const m = typeSetting.value[type][key];

          f[key] = m;
        }
        if (type == 2) {
          f.page_margin = pageStyleData.value.page_margin - f.left_margin;
        } else {
          f.page_margin = 0;
        }
        console.log(f.page_margin);
        facade.value = f;
      }

      list.value.style = type;
    };

    const listIds = ref([]);

    const loadList = (refresh) => {
      if (finished.value) return false;
      if (pageLoading.value) return false;
      const query = JSON.parse(JSON.stringify(queryParams.value));
      if (refresh) {
        query.page = 1;
        query.limit =
          list.value.renderData.length > 10 ? list.value.renderData.length : 10;
      }
      pageLoading.value = true;
      loading.value = true;
      auctionList(query)
        .then((res) => {
          total.value = res.data.count;
          if (albumData.value.album_type === 3) {
            res.data.list.forEach((m) => {
              m.album_status = albumData.value.album_status;
            });
          }
          if (queryParams.value.page === 1 || refresh) {
            list.value.renderData = res.data.list;
          } else {
            list.value.renderData = list.value.renderData.concat(res.data.list);
          }
          listIds.value = list.value.renderData.map((m) => m.product_id);
          if (list.value.renderData.length >= res.data.count) {
            finished.value = true;
          }
          if (!refresh) {
            queryParams.value.page += 1;
          }
          pageLoading.value = false;
          loading.value = false;
        })
        .catch((message) => Toast(message));
    };

    const showSearch = ref(false); // 是否展示搜索
    // 余额
    const account = computed(() => {
      return store.getters.account;
    });
    const openSearchAction = () => {
      keyword.value = "";
      showSearch.value = true;
      // document.getElementById("searchView").focus();
      nextTick(() => {
        setTimeout(() => {
          document.getElementById("searchView").focus();
        }, 100);
      });
    };

    const goSearchAction = () => {
      if (keyword.value) {
        tab.value = 0;
        queryParams.value.page = 1;
        queryParams.value.keyword = keyword.value;
        finished.value = false;

        loadList();
      }

      showSearch.value = false;
    };

    const closeSearchAction = () => {
      showSearch.value = false;
      keyword.value = "";
    };

    const clearSearchAction = () => {
      // 清除搜索结果
      queryParams.value.page = 1;
      queryParams.value.keyword = "";
      keyword.value = "";
      finished.value = false;

      loadList();
    };

    const checkAlbumBondPayAction = () => {
      // 检查是否缴纳保证金
      checkAlbumBondPay(id.value).then((res) => {
        isPayBond.value = res.data.is_pay;
      });
    };

    const getNumberPlateAction = (number) => {
      // 领取号牌
      albumData.value.numberPlate = number;
    };

    const showPayBondMoneyAction = () => {
      // 打开缴纳保证金弹层
      showPayBondMoney.value = true;
    };

    const payOk = () => {
      // 保证金支付成功
      showPayBondMoney.value = false;
      checkAlbumBondPayAction();
    };
    const payClear = () => {
      // 取消支付保证金
      showPayBondMoney.value = false;
    };

    const albumHeaderShow = ref(false);

    // useEventListener("scroll", () => {
    //   let scrollTop =
    //     window.scrollY ||
    //     window.pageYOffset ||
    //     document.documentElement.scrollTop ||
    //     document.body.scrollTop;
    //   if (scrollTop > 80 && !albumHeaderShow.value) {
    //     albumHeaderShow.value = true;
    //   } else if (scrollTop < 80 && albumHeaderShow.value) {
    //     albumHeaderShow.value = false;
    //   }
    // });

    let scrollTop = ref(0);
    const scrollBox = ref(null);
    const handleScroll = () => {
      scrollTop.value = scrollBox.value.scrollTop;
      if (scrollTop.value > 80 && !albumHeaderShow.value) {
        albumHeaderShow.value = true;
      } else if (scrollTop.value < 80 && albumHeaderShow.value) {
        albumHeaderShow.value = false;
      }
    }

    const countDownFinish = () => {
      setTimeout(() => {
        loadDetail();
      }, 1500);
    };

    const backAction = () => {
      if (window.history.length <= 1) {
        router.replace("/");
      } else {
        router.back();
      }
    };

    const tab = ref(0); // 0 全部  1我的出价  2即将结拍
    const changeTabAction = (val) => {
      if (tab.value !== val) {
        tab.value = val;
        queryParams.value.page = 1;
        queryParams.value.userbid = 0;
        queryParams.value.status = "";
        queryParams.value.order = "order_num";
        if (val === 1) {
          queryParams.value.userbid = 1;
        } else if (val === 2) {
          queryParams.value.status = 3;
          if (albumData.value.album_type === 1) {
            queryParams.value.order = "end";
          }
        }
        finished.value = false;
        pageLoading.value = false;
        loading.value = false;

        loadList();
      }
    };

    const isConfirm = ref(false);
    const isConfirmAction = () => {
      isConfirm.value = true;
    };
    const kong = () => {};

    const confirmOk = () => {
      if (isConfirm.value) {
        localStorage.setItem(`clickPageNoticeMsg${id.value}`, 1);
        pNMSG.value = "";
      } else {
        Toast("请先阅读并同意即刻拍参拍须知");
      }
    };

    const showFollowOfficial = ref(false);
    const is_follow_official_account = computed(() => {
      return store.getters.is_follow_official_account;
    });

    const showFollowOfficialAction = () => {
      showFollowOfficial.value = true;
    };
    const loadingr = ref(false);
    const onRefresh = () => {
      loadDetail();
      setTimeout(() => {
        loadingr.value = false;
      }, 1000);
    }
    return {
      id,
      tab,
      albumData,
      pageStyleData,
      pageContentData,
      pageStyle,
      queryParams,
      keyword,
      loading,
      finished,
      list,
      facade,
      typeSetting,
      total,
      showSearch,
      showPayBondMoney,
      isPayBond,
      albumHeaderShow,
      parseTime,
      color_data,
      account,
      pNTitle,
      pNMSG,
      isConfirm,
      showFollowOfficial,
      is_follow_official_account,

      loadList,
      editTypeAction,
      openSearchAction,
      goSearchAction,
      clearSearchAction,
      getNumberPlateAction,
      checkAlbumBondPay,
      showPayBondMoneyAction,
      payOk,
      payClear,
      closeSearchAction,
      countDownFinish,
      backAction,
      changeTabAction,
      isConfirmAction,
      kong,
      confirmOk,
      showFollowOfficialAction,

      scrollTop,
      scrollBox,
      handleScroll,
      loadingr,
      onRefresh
    };
  },
};
</script>

<style lang="scss" scoped>
.album-detail {
  width: 100vw;
  min-height: 100vh;
  background-position: center;
  background-attachment: fixed;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  padding-bottom: 180px;

  .tab {
    height: 100px;
    position: relative;
    .item {
      font-weight: 500;
      margin-right: 30px;
      padding: 20px 0;
      position: relative;

      span {
        font-weight: 400;
        color: #999;
        font-size: 24px;
      }

      .bg {
        height: 6px;
        width: 32px;
        border-radius: 4px;
        display: none;
        position: absolute;
        left: 50%;
        margin-left: -16px;
        bottom: 10px;
      }

      &.curr {
        font-size: 28px;

        .bg {
          display: block;
        }
      }
    }

    .search {
      position: absolute;
      width: 200px;
      right: 0;
      top: 22px;
      height: 56px;
      background: #f5f5f5;
      border-radius: 28px;
      padding: 0 20px;
      box-sizing: border-box;
      z-index: 10;

      input {
        border: none;
        background: none;
      }

      .icon {
        width: 32px;
        height: 32px;
        display: block;
        margin: 0 10px;
      }
    }
  }
  .icon {
    .icon-view {
      width: 32px;
      height: 100px;
      padding: 0 10px 0 20px;
      display: flex;
      align-items: center;
    }
    img {
      width: 32px;
      height: 32px;
    }
  }
}

.album-header {
  background: #fff;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  z-index: 100;

  .album-header-t {
    height: 88px;
    .back {
      margin-left: 32px;
      height: 88px;
      img {
        width: 48px;
        height: 48px;
        display: block;
      }
    }

    .n {
      font-size: 32px;
      margin-right: 32px;
    }
  }

  .album-header-b {
    padding: 10px 32px 20px 32px;
    .search {
      width: 200px;
      height: 56px;
      background: #f5f5f5;
      border-radius: 28px;
      padding: 0 20px;
      box-sizing: border-box;
      z-index: 10;

      input {
        border: none;
        background: none;
      }
      img {
        width: 32px;
        height: 32px;
        display: block;
        margin: 0 10px;
      }
    }
    .search-icon {
      height: 32px;
      height: 32px;
      img {
        height: 32px;
        height: 32px;
        display: block;
      }
    }
  }
}

.close-search {
  height: 40px;
  padding: 0 20px;
  line-height: 40px;
  border: 1px solid #ccc;
  color: #999;
  border-radius: 20px;
  font-size: 20px;
}

.mr3 {
  margin-right: 3px;
}
.ml3 {
  margin-left: 3px;
}

.show-confirm {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  background: #fff;
  flex-direction: column;
}

.show-title {
  text-align: center;
  font-size: 40px;
  font-weight: 500;
  position: relative;
  height: 80px;
  line-height: 80px;
  padding-top: 10px;
}

.n-header-back {
  position: absolute;
  left: 15px;
  top: 10px;
  height: 80px;
  display: flex;
  align-items: center;
}
.n-header-back .img,
.n-header-home .img {
  width: 50px;
  height: 50px;
  display: block;
}
.show-content {
  margin-top: 10px;
  position: relative;
}
.btn-bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  background: #fff;
  padding: 30px 0 50px 0;
}
.show-content .c {
  padding: 0 30px;
  font-size: 26px;
  height: 100%;
  overflow-y: auto;
  max-height: 99999px;
}
.btn-bottom .l {
  margin-left: 30px;
  flex: 1;
}
.btn-bottom .r {
  margin: 0 30px;
  width: 300px;
  height: 80px;
  line-height: 80px;
  text-align: center;
  font-weight: 500;
  font-size: 32px;
  background: #ccc;
  color: #fff;
  border-radius: 8px;
}
.btn-bottom .r.hong {
  background: #ae0000;
  color: #fff;
}

.bottom-checkbox {
  align-items: flex-start;
  margin-top: 20px;
  padding: 30px 2px 160px 20px;
  border-top-left-radius: 14px;
  border-top-right-radius: 14px;
  box-shadow: 0 -4px 16px rgba(0, 0, 0, 0.1);
}
.bottom-checkbox .l {
  transform: scale(0.7, 0.7);
}

.bottom-checkbox .r {
  margin-top: 2px;
  margin-left: 10px;
  max-height: 99999px;
  line-height: 40px;
  margin-right: 20px;
}
</style>
<style>
.album-detail .show-content .c p {
  font-size: 24px;
  line-height: 50px;
  max-height: 99999px;
}

.album-detail .show-content .van-checkbox__icon .van-icon {
  font-size: 0.4rem;
  width: 0.6rem;
  height: 0.6rem;
  line-height: 0.6rem;
}
.album-detail .show-content .van-checkbox__icon {
  width: 0.6rem;
  height: 0.6rem;
}
</style>