<template>
  <div
    class="flex align-items-c justify-b"
    :style="{ padding: `${facade.marginTop / 75}rem ${(pageInfo.page_margin) / 75}rem 0 ${(pageInfo.page_margin) / 75}rem`, background: '#ffffff', fontSize: (facade.fontSize / 75) + 'rem' }"
  >
    <div
      class="time1 flex align-items-c"
      :style="{ color: facade.timeColor }"
    >
      <AuctionStatus
        :status="facade.status_type"
        :type="albumData.status"
        sty="display: inline-block;position: relative; margin-right: 0.0666rem;"
      />
      <div class="flex align-items-c">
        <template v-if="albumData.album_status > 2">
          <div class="ml5">{{ pTime(albumData.album_end_time, '{y}年{m}月{d}日 {h}:{i}') }}</div>
          <div
            class="ml5"
            :style="{ fontWeight: facade.fontWeight }"
          >结拍</div>
        </template>
        <template v-else-if="albumData.album_status === 1 && albumData.album_start_time && (albumData.goTime <= 0 || albumData.goTime > 3600 * 6)">
          <div
            class="bg ml5"
            :style="{ color: facade.activeColor, fontWeight: facade.fontWeight }"
          >{{ albumStartTime[1] }}</div>
          <div>月</div>
          <div
            class="bg"
            :style="{ color: facade.activeColor, fontWeight: facade.fontWeight }"
          >{{ albumStartTime[2] }}</div>
          <div>日</div>
          <div
            class="bg ml2"
            :style="{ color: facade.activeColor, fontWeight: facade.fontWeight }"
          >{{ albumStartTime[3] }}:{{ albumStartTime[4] }}</div>
          <div class="ml5">开拍</div>
        </template>
        <template v-else-if="albumData.album_status === 2 && albumData.first_end_time && (albumData.goTime <= 0 || albumData.goTime > 3600 * 6 || albumData.end_type == 2)">
          <div
            class="bg ml5"
            :style="{ color: facade.activeColor, fontWeight: facade.fontWeight }"
          >{{ firstEndTime[1] }}</div>
          <div>月</div>
          <div
            class="bg"
            :style="{ color: facade.activeColor, fontWeight: facade.fontWeight }"
          >{{ firstEndTime[2] }}</div>
          <div>日</div>
          <div
            class="bg ml2"
            :style="{ color: facade.activeColor, fontWeight: facade.fontWeight }"
          >{{ firstEndTime[3] }}:{{ firstEndTime[4] }}</div>
          <template v-if="albumData.end_type == 2 && albumData.album_type == 1 && albumData.album_end_time">
            <div class="ml2">至</div>
            <template v-if="albumEndTime[1] != firstEndTime[1]">
              <div
                class="bg ml5"
                :style="{ color: facade.activeColor, fontWeight: facade.fontWeight }"
              >{{ albumEndTime[1] }}</div>
              <div>月</div>
              <div
                class="bg"
                :style="{ color: facade.activeColor, fontWeight: facade.fontWeight }"
              >{{ albumEndTime[2] }}</div>
              <div>日</div>
            </template>
            <template v-else-if="albumEndTime[2] != firstEndTime[2]">
              <div
                class="b ml5"
                :style="{ color: facade.activeColor, fontWeight: facade.fontWeight }"
              >{{ albumEndTime[2] }}</div>
              <div>日</div>
            </template>
            <div
              class="bg ml2"
              :style="{ color: facade.activeColor, fontWeight: facade.fontWeight }"
            >{{ albumEndTime[3] }}:{{ albumEndTime[4] }}</div>
          </template>
          <div class="ml5">结拍</div>
        </template>
        <template v-else-if="albumData.album_status === 2 && albumData.album_type == 4">出价后{{ albumData.interval_time / 60 }}分钟结拍</template>
        <template v-else-if="albumData.album_status === 2 && albumData.album_type != 1">当前{{albumData.now_auction_order_num}}/{{albumData.auction_num}}件</template>
        <template v-else-if="(albumData.album_status === 1 || (albumData.album_status === 2 && albumData.end_type == 1)) && albumData.goTime > 0 && albumData.goTime <= 3600 * 6">
          <div class="flex align-items-c ft12">
            <div class="ml5 mr2">{{albumData.album_status === 1 ? '距开拍' : '距结拍'}}</div>
            <van-count-down :time="albumData.goTime * 1000">
              <template #default="timeData">
                <div class="flex align-items-c ft12">
                  <template v-if="timeData.hours">
                    <div
                      :class="facade.time_type == 1 ? 'bg1' : 'bg'"
                      :style="facade.time_type == 1 ? 'background:' + facade.bgBtnColor + ';color: ' + facade.bgColor + ';' : 'color:' + facade.activeColor + ';'"
                    >{{ timeData.hours }}</div>
                    <div style="font-size: 0.32rem;">时</div>
                  </template>
                  <div
                    :class="facade.time_type == 1 ? 'bg1' : 'bg'"
                    :style="facade.time_type == 1 ? 'background:' + facade.bgBtnColor + ';color: ' + facade.bgColor + ';' : 'color:' + facade.activeColor + ';'"
                  >{{ timeData.minutes }}</div>
                  <div style="font-size: 0.32rem;">分</div>
                  <div
                    :class="facade.time_type == 1 ? 'bg1' : 'bg'"
                    :style="facade.time_type == 1 ? 'background:' + facade.bgBtnColor + ';color: ' + facade.bgColor + ';' : 'color:' + facade.activeColor + ';'"
                  >{{ timeData.seconds }}</div>
                  <div style="font-size: 0.32rem;">秒</div>
                </div>
              </template>
            </van-count-down>
          </div>
        </template>
      </div>
    </div>
    <div
      class="r-btn flex align-items-c ft12 fWeight500"
      :style="{ color: facade.color, fontWeight: facade.fontWeight }"
      v-if="albumData.album_status !== 3"
      @click="likeAction"
    >
      <img
        src="https://artimg2.artart.cn/image/20220416/cd5ff7d602654419c54bc945f5595ffa.png"
        alt=""
      >
      <div v-if="albumData.isCare != 1">{{ albumData.album_status === 1 ? '开拍提醒' : '结拍提醒'}}</div>
      <div v-else>取消提醒</div>
    </div>
  </div>
</template>

<script>
import { computed, toRefs } from "vue";
import { useStore } from "vuex";
import { CountDown, Toast } from "vant";
import { parseTime } from "@/utils/util";
import { relationCreate, relationDel } from "@/api/user";
import AuctionStatus from "@/components/DiyComponent/AuctionStatus";

export default {
  components: {
    [CountDown.name]: CountDown,
    AuctionStatus,
  },
  props: {
    albumData: [String, Object],
    facade: Object,
    pageInfo: Object,
  },
  setup(props, content) {
    const { albumData } = toRefs(props);
    const store = useStore();

    const pTime = (time, mat) => {
      return parseTime(time * 1000, mat);
    };

    const firstEndTime = computed(() => {
      return albumData
        ? parseTime(
            albumData.value.first_end_time,
            "{y},{m},{d},{h},{i}"
          ).split(",")
        : [];
    });

    const albumStartTime = computed(() => {
      return albumData
        ? parseTime(
            albumData.value.album_start_time,
            "{y},{m},{d},{h},{i}"
          ).split(",")
        : [];
    });
    const albumEndTime = computed(() => {
      return albumData
        ? parseTime(
            albumData.value.album_end_time,
            "{y},{m},{d},{h},{i}"
          ).split(",")
        : [];
    });

    // 右按钮样式
    const rightBtnStyle = computed(() => {
      return store.getters.right_button_style
        ? store.getters.right_button_style
        : {};
    });

    const is_follow_official_account = computed(() => {
      return store.getters.is_follow_official_account;
    });
    const qrcode_url = computed(() => {
      return store.getters.qrcode_url;
    });

    const likeAction = () => {
      let action = relationCreate;
      if (!is_follow_official_account.value && qrcode_url.value) {
        content.emit('showFollowOfficial');
        return false;
      }
      const data = {
        type_id: albumData.value.album_id,
        type_ids: albumData.value.album_id,
        type: 12,
      };
      if (albumData.value.isCare) {
        action = relationDel;
      }
      action(data)
        .then(() => {
          albumData.value.isCare = albumData.value.isCare == 1 ? 0 : 1;
        })
        .catch((message) => Toast(message));
    };

    return {
      pTime,
      is_follow_official_account,
      rightBtnStyle,
      likeAction,
      firstEndTime,
      albumStartTime,
      albumEndTime,
    };
  },
};
</script>

<style lang="scss" scoped>
.time1 {
  .bg1 {
    min-width: 38px;
    height: 40px;
    text-align: center;
    padding: 0 8px;
    margin: 0 6px;
    border-radius: 4px;
    font-weight: 400;
    line-height: 36px;
    font-size: 24px;
    box-sizing: border-box;
  }
  .bg {
    text-align: center;
    box-sizing: border-box;
  }

  .item {
    display: flex;
    align-items: center;
    margin-left: 10px;
    span {
      color: #cd2c2c;
      font-size: 32px;
      font-weight: 500;
      margin-right: 10px;
    }
  }
}
.r-btn {
  img {
    width: 32px;
    height: 32px;
    display: block;
    margin-right: 4px;
  }
}
</style>