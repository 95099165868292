<template>
<div>
  <div v-if="albumData.venue" :style="{ lineHeight: (facade.meeting_lineHeight / 75) + 'rem', padding: `${facade.marginTop / 75}rem ${(pageInfo.page_margin) / 75}rem 0 ${(pageInfo.page_margin / 75)}rem`, background: '#ffffff' }">
    <span :style="{ fontSize: (facade.meeting_fontSize / 75) + 'rem', fontWeight: facade.meeting_fontWeight, color: facade.meeting_color }">{{ albumData.venue.title }}</span>
  </div>
  <div :style="{ lineHeight: (facade.lineHeight / 75) + 'rem', padding: `0 ${(pageInfo.page_margin / 75)}rem`, background: '#ffffff' }">
    <span :style="{ fontSize: (facade.fontSize / 75) + 'rem', fontWeight: facade.fontWeight, color: facade.color }">{{ albumData.album_name }}</span>
  </div>
</div>
</template>

<script>
export default {
  props: {
    albumData: [String, Object],
    facade: Object,
    pageInfo: Object,
  },
  setup() {},
};
</script>

<style lang="scss" scoped>
</style>