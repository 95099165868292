<template>
  <div
    v-if="albumData"
    :class="{
      'flex': albumData.album_config.image_show_style == 2 && pageInfo.show_header_view
    }"
    :style="{ padding: `${facade.marginTop / 75}rem ${(pageInfo.page_margin / 75)}rem 0 ${(pageInfo.page_margin / 75)}rem`, background: '#ffffff' }"
  >
    <van-image
      v-if="pageInfo.show_header_view"
      :style="{
        borderRadius: (facade.img_radius / 75) + 'rem',
        width: albumData.album_config.image_show_style == 1 ? '100%' : '3.33333rem',
        height: albumData.album_config.image_show_style == 1 ? 'auto' : '4.44rem',
        display: 'block',
        overflow: 'hidden'
      }"
      :src="albumData.album_image + '!m640'"
      fit="cover"
    />
    <div v-if="albumData.album_config.image_show_style == 1 || !pageInfo.show_header_view">
      <div
        class="desc flex align-items-c"
        @click="showCommission"
      >
        <div
          class="desc-item flex-one flex align-items-c"
          :style="{ fontSize: (facade.fontSize / 75) + 'rem', color: facade.color, height: (facade.lineHeight / 75) + 'rem' }"
        >
          <div class="item">拍品<span :style="{ color: facade.activeColor, fontWeight: facade.activeFontWeight }">{{albumData.auction_num}}件</span></div>
          <div
            class="item"
            v-if="albumData.bond_type === 2"
          >保证金<span :style="{ color: facade.activeColor, fontWeight: facade.activeFontWeight }">1:{{albumData.bond_bid_ratio}}</span></div>
          <div
            class="item"
            v-else-if="albumData.bond_type === 1 && albumData.bond_price"
          >保证金<span :style="{ color: facade.activeColor, fontWeight: facade.activeFontWeight }">{{ albumData.bond_price }}{{ merchantInfo.fee_type_text }}</span></div>
          <div class="item">佣金<span :style="{ color: facade.activeColor, fontWeight: facade.activeFontWeight }">{{ albumData.commission_config_json.commission }}<template v-if="albumData.commission_config_json.after_commission"> - {{ albumData.commission_config_json.after_commission }}</template>%</span></div>
        </div>
        <img
          v-if="albumData.commission_config_json.after_commission"
          src="https://artimg2.artart.cn/image/20220105/c4a32792718b472c47d4b5dcab146abe.png"
          alt=""
        >
      </div>
      <div class="desc flex align-items-c">
        <div
          class="desc-item flex-one flex align-items-c"
          :style="{ fontSize: (facade.fontSize / 75) + 'rem', color: facade.color, height: (facade.lineHeight / 75) + 'rem' }"
        >
          <div class="item">围观<span :style="{ color: facade.activeColor, fontWeight: facade.activeFontWeight }">{{ albumData.virtual_click_num }}次</span></div>
          <div class="item">出价<span :style="{ color: facade.activeColor, fontWeight: facade.activeFontWeight }">{{ albumData.bid_num }}次</span></div>
          <div v-if="albumData.album_config.is_show_turnovers == 1 && albumData.transaction_price" class="item">成交额<span :style="{ color: facade.activeColor, fontWeight: facade.activeFontWeight }">{{ bigNumberTransform(albumData.transaction_price) }}</span></div>
        </div>
        <!-- <img
        src="https://artimg2.artart.cn/image/20220105/c4a32792718b472c47d4b5dcab146abe.png"
        alt=""
      > -->
      </div>
      <div class="desc flex align-items-c">
        <div
          class="desc-item flex-one flex align-items-c"
          :style="{ fontSize: (facade.fontSize / 75) + 'rem', color: facade.color, height: (facade.lineHeight / 75) + 'rem' }"
          @click="goPageAction('/users/agreement?type=1&merchantid=' + albumData.mer_id)"
        >
          <div class="item"><span :style="{ color: facade.activeColor, margin: 0, fontWeight: facade.activeFontWeight }">参拍须知/物流运输/注意事项</span></div>
        </div>
        <img
          src="https://artimg2.artart.cn/image/20220105/c4a32792718b472c47d4b5dcab146abe.png"
          alt=""
        >
      </div>
    </div>
    <div
      class="flex-one heng"
      v-else-if="albumData.album_config.image_show_style == 2"
    >
      <div class="desc flex align-items-c">
        <div
          class="desc-item flex-one flex align-items-c"
          :style="{ fontSize: (facade.fontSize / 75) + 'rem', color: facade.color, height: (facade.lineHeight / 75) + 'rem' }"
          @click="showCommission"
        >
          <div
            class="item"
            v-if="albumData.bond_type === 2"
          >保证金<span :style="{ color: facade.activeColor, fontWeight: facade.activeFontWeight }">1:{{albumData.bond_bid_ratio}}</span></div>
          <div
            class="item"
            v-if="albumData.bond_type === 1 && albumData.bond_price"
          >保证金<span :style="{ color: facade.activeColor, fontWeight: facade.activeFontWeight }">{{ albumData.bond_price }}{{ merchantInfo.fee_type_text }}</span></div>
          <div class="item">佣金<span :style="{ color: facade.activeColor, fontWeight: facade.activeFontWeight }">{{ albumData.commission_config_json.commission }}<template v-if="albumData.commission_config_json.after_commission">-{{ albumData.commission_config_json.after_commission }}</template>%</span></div>
        </div>
        <img
          v-if="albumData.commission_config_json.after_commission"
          src="https://artimg2.artart.cn/image/20220105/c4a32792718b472c47d4b5dcab146abe.png"
          alt=""
        >
      </div>
      <div class="desc flex align-items-c">
        <div
          class="desc-item flex-one flex align-items-c"
          :style="{ fontSize: (facade.fontSize / 75) + 'rem', color: facade.color, height: (facade.lineHeight / 75) + 'rem' }"
        >
          <div class="item">拍品<span :style="{ color: facade.activeColor, fontWeight: facade.activeFontWeight }">{{albumData.auction_num}}件</span></div>
          <div class="item">围观<span :style="{ color: facade.activeColor, fontWeight: facade.activeFontWeight }">{{ albumData.virtual_click_num }}次</span></div>
        </div>
      </div>
      <div class="desc flex align-items-c">
        <div
          class="desc-item flex-one flex align-items-c"
          :style="{ fontSize: (facade.fontSize / 75) + 'rem', color: facade.color, height: (facade.lineHeight / 75) + 'rem' }"
        >
          <!-- <div class="item">报名<span :style="{ color: facade.activeColor }">155人</span></div> -->
          <div class="item">出价<span :style="{ color: facade.activeColor, fontWeight: facade.activeFontWeight }">{{ albumData.bid_num }}次</span></div>
          <div v-if="albumData.album_config.is_show_turnovers == 1 && albumData.transaction_price" class="item">成交额<span :style="{ color: facade.activeColor, fontWeight: facade.activeFontWeight }">{{ bigNumberTransform(albumData.transaction_price) }}</span></div>
        </div>
        <!-- <img
        src="https://artimg2.artart.cn/image/20220105/c4a32792718b472c47d4b5dcab146abe.png"
        alt=""
      > -->
      </div>
      <div class="desc flex align-items-c">
        <div
          class="desc-item flex-one flex align-items-c"
          :style="{ fontSize: (facade.fontSize / 75) + 'rem', color: facade.color, height: (facade.lineHeight / 75) + 'rem' }"
        >
          <div
            class="item"
            @click="goPageAction('/users/agreement?type=1&merchantid=' + albumData.mer_id)"
          ><span :style="{ color: facade.activeColor, margin: 0, fontWeight: facade.activeFontWeight }">参拍须知/物流运输/注意事项</span></div>
        </div>
        <img
          src="https://artimg2.artart.cn/image/20220105/c4a32792718b472c47d4b5dcab146abe.png"
          alt=""
        >
      </div>
    </div>
    <div
      class="show-commission item-flex-center"
      @click="hideCommission"
      v-if="showComm"
    >
      <div
        class="commission-content"
        @click.stop="kong"
      >
        <div class="comm-content">{{parseTime(albumData.commission_config_json.commission_time, '{y}年{m}月{d}日')}}前，佣金{{albumData.commission_config_json.commission}}%</div>
        <div class="comm-content">{{parseTime(albumData.commission_config_json.commission_time, '{y}年{m}月{d}日')}}后，佣金{{albumData.commission_config_json.after_commission}}%</div>
        <div
          class="comm-btn"
          :style="rightBtnStyle"
          @click="hideCommission"
        >我知道了</div>
      </div>
    </div>
  </div>
</template>

<script>
import { Image as VanImage } from "vant";
import { useRouter } from "vue-router";
import { computed, ref, toRefs } from "vue";
import { useStore } from "vuex";
import { parseTime, bigNumberTransform } from "@/utils/util";

export default {
  components: {
    [VanImage.name]: VanImage,
  },
  props: {
    albumData: [String, Object],
    facade: Object,
    pageInfo: Object,
  },
  setup(props) {
    const { albumData } = toRefs(props);
    const router = useRouter();
    const store = useStore();
    const showComm = ref(false);

    const goPageAction = (link) => {
      if (link) {
        router.push(link);
      }
    };

    const merchantInfo = computed(() => {
      if (props.albumData.merchant) {
        return props.albumData.merchant;
      }
      return store.getters.merchantInfo ? store.getters.merchantInfo : {};
    });

    // 右按钮样式
    const rightBtnStyle = computed(() => {
      return store.getters.right_button_style
        ? store.getters.right_button_style
        : {};
    });
    const showCommission = () => {
      if (
        albumData.value.commission_config_json &&
        albumData.value.commission_config_json.after_commission &&
        albumData.value.commission_config_json.commission_time
      ) {
        showComm.value = true;
      }
    };
    const hideCommission = () => {
      showComm.value = false;
    };
    const kong = () => {};

    return {
      merchantInfo,
      showComm,
      rightBtnStyle,

      goPageAction,
      showCommission,
      parseTime,
      hideCommission,
      kong,
      bigNumberTransform
    };
  },
};
</script>

<style lang="scss" scoped>
.desc {
  border-bottom: 2px solid #eee;

  .desc-item {
    font-size: 24px;

    &:first-child {
      border-top: none;
    }

    .item {
      margin-right: 40px;

      span {
        margin-left: 10px;
        font-weight: 500;
      }
    }
  }
  img {
    width: 32px;
    height: 32px;
  }
}

.heng {
  margin-left: 20px;
  .desc {
    .item {
      margin-right: 30px;
    }
  }
}

.show-commission {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 9999;
  background: rgba(0, 0, 0, 0.5);
}

.commission-content {
  width: 80%;
  background: #fff;
  border-radius: 12px;
  padding-top: 30px;
}
.comm-content {
  padding: 10px 40px 0 40px;
  font-size: 32px;
}

.comm-btn {
  margin: 30px 35px;
  padding: 15px 0;
  text-align: center;
}
</style>