<template>
  <div
    class="album_footer"
    v-if="albumData.album_id"
  >
    <template v-if="(albumData.album_status ==  1 || albumData.album_status == 2) && ((albumData.bond_type == 1 && (albumData.bond_price) && !isPayBond) || (albumData.live_id && (albumData.album_status == 2 || albumData.album_status == 1)))">
      <div
        class="bid-top flex align-items-c"
        v-if="((albumData.bond_type == 2 && userNumber) || (userNumberType == '免保号牌' || userNumberType == '特殊号牌') || ((userNumber && albumData.live_id && (albumData.album_status == 2 || albumData.album_status == 1)) && ((albumData.bond_type == 1 && (albumData.bond_price) && !isPayBond) || !userNumber)))"
      >
        <div class="number flex-one">号牌 <span class="fw500 color-black">{{ userNumber }}</span> <text
            class="vip"
            v-if="userNumberType == '免保号牌' || userNumberType == '特殊号牌'"
          >VIP免保证金</text>
          <template v-else-if="albumData.bond_type == 2">参拍额度 <span class="fw500 color-black">{{ formatPriceNumber(account * albumData.bond_bid_ratio, 2) }}</span></template>
        </div>
        <div
          v-if="albumData.bond_type == 2"
          class="cz"
          @click="goAtherPage('/users/recharge')"
        >
          <div>充值</div>
          <img src="https://artimg2.artart.cn/image/20220416/e361ec375b10cb2e2b2475707181f47d.png">
        </div>
      </div>

    </template>

    <div class="flex align-items-e pr20">
      <div
        class="flex align-items-c"
        style="margin-right: 0.42666rem;"
      >
        <div
          class="icon-img flex align-items-c justify-c"
          style="margin-left: 0.42666rem;"
        >
          <img src="https://artimg2.artart.cn/image/20220108/9e1bf2c2f79de4fd542675f534f0910f.png">
        </div>
        <div
          class="icon-img flex align-items-c justify-c"
          style="margin-left: 0.21333rem;"
          @click="shareAction"
        >
          <img src="https://artimg2.artart.cn/image/20220108/8991f37810e6699b8e062a13b7336ce1.png">
        </div>
      </div>
      <div
        class="flex-one"
        v-if="albumData.album_status == 3"
      >
        <div
          v-if="albumData.live && albumData.live.extend_data && albumData.live.extend_data.is_watch_video_playback == 1"
          class="bottom-btn l mr5"
          :style="rightBtnStyle"
          @click="goAtherPage(('/pages/Live/Live?id=') + albumData.live_id)"
        >
          <div>观看直播回放</div>
        </div>
        <div
          v-else
          class="bottom-btn r hui"
          :style="rightBtnStyle"
        >
          <img
            src="https://artimg2.artart.cn/image/20220130/fb1f36cece737b05781694afa7a936cd.png"
            alt=""
          >
          <div>已结拍</div>
        </div>
      </div>
      <div
        class="flex-one"
        v-else
      >
        <template v-if="!((albumData.bond_type == 1 && (albumData.bond_price) && !isPayBond) || (albumData.live_id && (albumData.album_status == 2 || albumData.album_status == 1)))">
          <div
            class="bid-top flex align-items-c"
            style="padding: 0 0 0.266667rem 0;"
            v-if="((albumData.bond_type == 2 && userNumber) || (userNumberType == '免保号牌') || (userNumberType == '特殊号牌') || ((userNumber && albumData.live_id && (albumData.album_status == 2 || albumData.album_status == 1)) && ((albumData.bond_type == 1 && (albumData.bond_price) && !isPayBond) || !userNumber)))"
          >
            <div class="number flex-one">号牌 <span class="fw500 color-black">{{ userNumber }}</span> <text
                class="vip"
                v-if="userNumberType == '免保号牌' || userNumberType == '特殊号牌'"
              >VIP免保证金</text>
              <template v-else-if="albumData.bond_type == 2">参拍额度 <span class="fw500 color-black">{{ formatPriceNumber(account * albumData.bond_bid_ratio, 2) }}</span></template>
            </div>
            <div
              v-if="albumData.bond_type == 2"
              class="cz"
              @click="goAtherPage('/users/recharge')"
            >
              <div>充值</div>
              <img src="https://artimg2.artart.cn/image/20220416/e361ec375b10cb2e2b2475707181f47d.png">
            </div>
          </div>

        </template>

        <div class="flex align-items-c">
          <div
            v-if="albumData.bond_type == 1 && userNumber && userNumberType != '免保号牌' && !((albumData.live_id && (albumData.album_status == 2 || albumData.album_status == 1)) && ((albumData.bond_type == 1 && (albumData.bond_price) && !isPayBond) || !userNumber))"
            class="lh80 ft14 color-gray mr20"
          >号牌 <span class="fw500 color-black">{{ userNumber }}</span></div>
          <div
            v-if="albumData.bond_type == 1 && (albumData.bond_price) && !isPayBond"
            class="bottom-btn l"
            :style="rightBtnStyle"
            @click="payBondAction"
          >
            <div>交保证金</div>
          </div>
          <div
            v-else-if="!userNumber"
            class="bottom-btn l"
            :style="rightBtnStyle"
            @click="useNumberPlateAction"
          >领取号牌</div>
          <div
            v-if="albumData.live_id && (albumData.album_status == 2 || albumData.album_status == 1)"
            class="bottom-btn l ml10"
            :class="{ 'm0': !(albumData.bond_type == 1 && (albumData.bond_price) && !isPayBond) && (userNumber) }"
            :style="rightBtnStyle"
            @click="goAtherPage(('/pages/Live/Live?id=') + albumData.live_id+'&merchantid='+albumData.mer_id)"
          >
            <div>进入{{ albumData.album_type == 1 ? '直播间' : '拍场' }}</div>
          </div>
        </div>
      </div>
      <!-- <template>
          <div
            v-if="!userNumber"
            class="bottom-btn l"
            :style="rightBtnStyle"
            @click="useNumberPlateAction"
          >领取号牌</div>
          <div
            class="bottom-txt"
            v-if="userNumber"
          >号牌 <span :style="{ color: colorData.specialColor }">{{ userNumber }}</span></div>
          <template v-if="userNumber && albumData.bond_type == 2">
            <div class="bottom-txt flex-one">参拍额度 <span :style="{ color: colorData.specialColor }">50万</span></div>
            <div class="bottom-a"><span>充值</span></div>
          </template>
          <template v-if="userNumber && albumData.bond_type == 1 && !isPayBond">
            <div
              class="bottom-btn l"
              :style="rightBtnStyle"
              @click="payBondAction"
            >交保证金</div>
          </template>
        </template> -->
      <!-- <div class="bottom-txt flex-one">VIP免保证金</div> -->
      <!-- <div
        class="bottom-btn r mr20"
        :class="{ 'no-radius': buttonData.style === 2 }"
        :style="rightBtnStyle"
      >进入拍场</div> -->
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { computed, toRefs } from "vue";
import { Toast, ImagePreview } from "vant";
import { useRouter } from "vue-router";
import { userNumberPlateHandle } from "@/api/auction";
import { sharePosterImg } from "@/api/system";
import { formatPriceNumber } from "@/utils/util";

export default {
  props: {
    albumData: [Object, String],
    isPayBond: Boolean,
  },
  setup(props, content) {
    const { albumData } = toRefs(props);
    const store = useStore();
    const router = useRouter();

    // 获取号牌
    const userNumber = computed(() => {
      return albumData.value.numberPlate && albumData.value.numberPlate.number
        ? albumData.value.numberPlate.number
        : "";
    });

    // 获取号牌
    const userNumberType = computed(() => {
      return albumData.value.numberPlate &&
        albumData.value.numberPlate.number_type
        ? albumData.value.numberPlate.number_type
        : "";
    });

    // 获取button样式集合
    const colorData = computed(() => {
      return store.getters.color_data ? store.getters.color_data : {};
    });
    // 右按钮样式
    const rightBtnStyle = computed(() => {
      return store.getters.right_button_style
        ? store.getters.right_button_style
        : {};
    });
    // 左按钮样式
    const leftBtnStyle = computed(() => {
      return store.getters.left_button_style
        ? store.getters.left_button_style
        : {};
    });

    const account = computed(() => {
      return store.getters.account.balance;
    });

    const is_follow_official_account = computed(() => {
      return store.getters.is_follow_official_account;
    });
    const qrcode_url = computed(() => {
      return store.getters.qrcode_url;
    });

    // 获取号牌
    const useNumberPlateAction = () => {
      if (!is_follow_official_account.value && qrcode_url.value) {
        content.emit('showFollowOfficial');
        return false;
      }
      if (albumData.value.album_config.check_user_idCard == 2) {
        store
          .dispatch("user/applyVerifyInfo")
          .then((type) => {
            if (type == 1 || type == 2) {
              userNumberAction();
            }
          })
          .catch((message) => Toast(message));
        return false;
      }
      userNumberAction();
    };
    const userNumberAction = () => {
      userNumberPlateHandle({
        venue_id: albumData.value.venue_id,
      })
        .then((res) => {
          content.emit("userNumberPlate", res.data);
        })
        .catch((message) => Toast(message));
    };

    const payBondAction = () => {
      if (!is_follow_official_account.value && qrcode_url.value) {
        content.emit('showFollowOfficial');
        return false;
      }
      if (!userNumber.value) {
        useNumberPlateAction();
      }
      content.emit("showPayBondMoney");
    };
    const goAtherPage = (link) => {
      if (link) {
        router.push(link);
      }
    };

    const shareAction = () => {
      console.log(store.getters);
      const data = {
        poster_type: "album",
        qrcode_type: 1,
        Independent_qrcode: 0,
        id: albumData.value.album_id,
        return_format: 1,
        inviteuid: store.getters.uid,
      };
      ImagePreview([sharePosterImg(data)]);
    };

    return {
      rightBtnStyle,
      leftBtnStyle,

      colorData,
      userNumber,
      userNumberType,
      account,
      formatPriceNumber,

      useNumberPlateAction,
      payBondAction,
      goAtherPage,
      shareAction,
    };
  },
};
</script>

<style lang="scss" scoped>
.album_footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  font-size: 18px;
  padding: 16px 0;
  color: #000;
  background: #fff;
  padding-bottom: 40px;
  .hengtiao {
    height: 10px;
    width: 268px;
    border-right: 5px;
    background: #000;
    position: absolute;
    bottom: 18px;
    left: 50%;
    margin-left: -134px;
  }
  .icon-img {
    width: 80px;
    height: 80px;
    img {
      width: 48px;
      height: 48px;
    }
  }
  .icon-img1 {
    height: 80px;
    margin-top: 16px;
    img {
      width: 48px;
      height: 48px;
    }
  }

  .bottom-btn {
    flex: 1;
    height: 80px;
    line-height: 72px;
    text-align: center;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;

    &.hui {
      background: #eee !important;
      border-color: #eee !important;
      color: #ccc !important;
    }

    &.l.no-radius {
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }
    &.r.no-radius {
      border-top-left-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
    }

    &.r {
      img {
        width: 32px;
        height: 32px;
        display: block;
        margin-right: 8px;
      }
    }
  }

  .bottom-txt,
  .bottom-a {
    font-size: 26px;
    margin-right: 20px;

    span {
      font-weight: 500;
    }
  }

  .bottom-a {
    color: #347ff4;
  }
}

.pr20 {
  padding-right: 20px;
}

.bid-top {
  padding: 0 32px 20px 32px;
  font-size: 28px;
  color: #666;
  .number {
    margin-right: 40px;

    span {
      font-family: NUM;
      color: #000;
    }

    .vip {
      color: #c1955c;
      margin-left: 20px;
    }
  }
  .cz {
    color: #347ff4;
    font-weight: 500;
  }
}

.bid-top .cz {
  color: #347ff4;
  font-weight: 500;
  display: flex;
  align-items: center;
}

.bid-top .cz img {
  width: 32px;
  height: 32px;
  display: block;
}

.lh80 {
  line-height: 80px;
}
</style>