<template>
  <div v-if="albumData.message || (albumData.album_config && albumData.album_config.image_show_style == 2)" :style="{ padding: `${facade.marginTop / 75}rem ${(pageInfo.page_margin / 75)}rem 0.26666rem ${(pageInfo.page_margin / 75)}rem`, background: '#ffffff' }">
    <div
      class="ft12"
      :style="{
        color: facade.color,
        fontWeight: facade.fontWeight,
        lineHeight: (facade.lineHeight / 75) + 'rem',
        overflow: 'hidden',
        maxHeight: maxH
      }"
    >
      <div v-html="albumData.message"></div>
    </div>
    <div
      v-if="facade.fold && albumData.message"
      class="fold flex align-items-c justify-c"
      @click="openAction"
    >
      <div>{{ nozhankai ? '展开' : '收起' }}</div>
      <img
        :class="{ 'fanzhuan' : !nozhankai }"
        src="https://artimg2.artart.cn/image/20220105/12cbbacfd35022e69421fe28bd244796.png"
        alt=""
      >
    </div>
  </div>
</template>

<script>
import { computed, ref, toRefs } from "vue";
export default {
  props: {
    albumData: [String, Object],
    facade: [Object,],
    pageInfo: Object,
  },
  setup(props) {
    const { facade } = toRefs(props);
    const nozhankai = ref(true);

    const openAction = () => nozhankai.value = !nozhankai.value

    const maxH = computed(() => {
      return (facade.value.fold && nozhankai.value ? ((facade.value.lineHeight * 3) / 75) + 'rem' : '')
    })

    return {
      nozhankai,
      maxH,

      openAction
    };
  },
};
</script>

<style lang="scss" scoped>
.fold {
  color: #003d7c;
  font-size: 24px;
  margin-top: 10px;
  img {
    width: 32px;
    height: 32px;

    &.fanzhuan {
      transform: rotate(180deg);
    }
  }
}
</style>